import React from 'react';
import _ from 'lodash';
import {Layout} from '../components/index';
import {getPages, Link} from '../utils';
import PostCards from '../components/PostCards';
import styled from 'styled-components';
import { useQueryParam, StringParam } from "use-query-params";


const Pagination = styled.div`
  grid-column-span: 2;
  display: flex;
  justify-content: center
`;

const StyledLink = styled(Link)`
  margin: 10px;
  font-weight: ${props => props.active ? '700' : 'inherit'};
`;

const Blog = (props) => {
  let all_posts = _.orderBy(getPages(props.pageContext.pages, '/blog'), 'frontmatter.date', 'desc');
  const [author, setAuthor] = useQueryParam("author", StringParam)
  const [category, setCategory] = useQueryParam("category", StringParam)
  let select_pages;
  let paginationLinks = [];
  let numberOfPages;

  _.map(all_posts, (post) => {
    let postDate = Date.parse(_.get(post, 'frontmatter.date'));
    let currentTime = Date.now();

    if (postDate > currentTime) {
      _.remove(all_posts, post)
    }
  });
  
  if (author) all_posts = all_posts.filter(post => post.frontmatter.author === author)
  if (category) all_posts = all_posts.filter(post => post.frontmatter.tags && post.frontmatter.tags.includes(category))

  numberOfPages = all_posts.length / props.pageContext.limit

  for (let index = 0; index < numberOfPages; index++) {
    let path = {
      name: index + 1,
      url: `/blog/${index + 1}`,
      active: false
    }

    if (index + 1 === props.pageContext.currentPage) {
      path.active = true;
    } 
    
    if (index === 0) path.url = '/blog/'

    if (author) path.url += `?author=${author}`

    paginationLinks.push(path)
  }

  if (props.pageContext.currentPage === 1) {
    select_pages = all_posts.slice(props.pageContext.skip, (props.pageContext.skip + props.pageContext.limit) - 1);
  } else {
    select_pages = all_posts.slice(props.pageContext.skip - 1, (props.pageContext.skip + props.pageContext.limit) - 1);
  }

  let headline = "Blog"

  if (author) headline = `Blog Posts by ${author}`

  if (category) headline = `Blog Posts about ${category}`

  return (
    <Layout {...props}>
      <div className="outer" style={{backgroundColor: "#FAFAFA"}}>
        <h2>{headline}</h2>
        <PostCards posts={select_pages} featured={all_posts[0] && all_posts[0].name} index />
        <Pagination>
          {paginationLinks.map(link => {
            if (link.active) {
              return <StyledLink active to={link.url}>{link.name}</StyledLink>
            } else {
              return <StyledLink to={link.url}>{link.name}</StyledLink>
            }
          })}
        </Pagination>
      </div>
    </Layout>
  );
}

export default Blog
